<template>
  <div id="columnChart">
    <b-card no-body>
      <b-card-header>
        <b-card-title>Số lượng thiết bị đã kiểm định</b-card-title>
      </b-card-header>

      <!-- chart -->
      <b-card-body>
        <vue-apex-charts
          type="bar"
          :options="option"
          :series="series"
          height="350"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constansts/ConstanstApi'

import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VueApexCharts,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    option: {
      type: Object,
      default: _ => {},
    },
  },
  // [35, 15, 18, 12, 20]

}
</script>

<style lang="scss">
</style>
